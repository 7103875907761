import BaseBean from "@/utils/BaseBean";

export interface IHdxxCardDataObj {
    utilInst:HdxxCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class HdxxCardUtil extends BaseBean{
    public dataObj:IHdxxCardDataObj;

    constructor(proxy:any,dataObj:IHdxxCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.hdxxData=await this.utils.OrderProviderApi.getAllHdxx({params:{queryType:1}});
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});
    }
    //提交
    public async submit():Promise<void>{
        if(!this.dataObj.form.fhrGsmc || !this.dataObj.form.shrGsmc || !this.dataObj.form.tzrGsmc){
            this.proxy.$message({type:'danger',message:'发货人公司名称、收货人公司名称、通知人公司名称不能为空'});
            return ;
        }
        this.utils.Tools.configBox({
            message:'确定提交吗?',
            sureFn:async ()=> {
                this.dataObj.form.saveType='submit';
                await this.proxy.engine.saveHandler();
            }
        });
    }
}